var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentOutlet)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"partner-google-map"},[_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100%","margin-top":"5px"},attrs:{"center":_vm.center,"zoom":_vm.gmapDefaultZoom,"options":_vm.mapOptions}},[_c('gmap-marker',{attrs:{"position":_vm.position,"clickable":true}}),_vm._l((_vm.getDefaultHexagonList()),function(item,index){return _c('gmap-polygon',{key:index,attrs:{"paths":item.points,"options":{
          fillColor: _vm.outletData.hexagon_zone.allowed_list.includes(item.label)
            ? '#000000'
            : '#FFFFFF',
          strokeOpacity: 0.2,
          fillOpacity: _vm.getPolygonFillOpacity('allowed_list', item.label)
            ? 0.5
            : 0.1,
        }}})})],2),_c('span',{staticClass:"flex flex-row justify-center items-center"},[_vm._v("Харилцагч та манай "),_c('strong',{staticClass:"text-baseRed px-2"},[_vm._v(_vm._s(_vm.outletData?.client_category))]),_vm._v(" харилцагч тул /"+_vm._s(_vm.outletData.delivery_zone_distance)+"/ хүргэлт хийж байна.")])],1):_c('p',{staticClass:"text-center pt50 pb50 text-secondary"},[_vm._v(" Харилцагчийн мэдээлэл олдсонгүй ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }