<template>
  <div>
    <div class="partner-google-map" v-if="currentOutlet" v-loading="loading">
      <gmap-map
        ref="map"
        :center="center"
        :zoom="gmapDefaultZoom"
        :options="mapOptions"
        style="width: 100%; height: 100%; margin-top: 5px"
      >
        <gmap-marker :position="position" :clickable="true" />
        <gmap-polygon
          v-for="(item, index) in getDefaultHexagonList()"
          :key="index"
          :paths="item.points"
          :options="{
            fillColor: outletData.hexagon_zone.allowed_list.includes(item.label)
              ? '#000000'
              : '#FFFFFF',
            strokeOpacity: 0.2,
            fillOpacity: getPolygonFillOpacity('allowed_list', item.label)
              ? 0.5
              : 0.1,
          }"
        >
        </gmap-polygon>
      </gmap-map>
      <span class="flex flex-row justify-center items-center"
        >Харилцагч та манай
        <strong class="text-baseRed px-2">{{
          outletData?.client_category
        }}</strong>
        харилцагч тул /{{ outletData.delivery_zone_distance }}/ хүргэлт хийж
        байна.</span
      >
    </div>
    <p v-else class="text-center pt50 pb50 text-secondary">
      Харилцагчийн мэдээлэл олдсонгүй
    </p>
  </div>
</template>

<script>
import {
  geoToH3,
  h3ToGeoBoundary,
  getDestinationH3IndexFromUnidirectionalEdge,
  getH3UnidirectionalEdgesFromHexagon,
} from "h3-js";
import mAxios from "../../../../helper/axiosInstance.js";
export default {
  name: "MapComponent",
  props: {
    currentOutlet: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      outletData: null,
      loading: false,
      center: {
        lat: 47.9174,
        lng: 106.9137,
      },
      strokeColors: [
        "#ff0000",
        "#ffa500",
        "#ffff66",
        "#81d8d0",
        "#407294",
        "#420420",
        "#065535",
        "#f6546a",
        "#0e2f44",
        "#660066",
        "#003366",
      ],
      hexagonResourceSize: 8,
      gmapDefaultZoom: 13,
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy",
      },
      position: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137),
      },
    };
  },
  computed: {},
  watch: {
    currentOutlet(newVal, oldVal) {
      if (newVal) {
        this.getOutletData(newVal._id);
      }
    },
  },
  mounted() {
    this.getOutletData(this.currentOutlet._id);
  },
  methods: {
    getNeighbourHexagons(h3) {
      const tempList = [];
      tempList.push(h3);
      const edges = getH3UnidirectionalEdgesFromHexagon(h3);
      edges.forEach((el) => {
        const calculatedH3 = getDestinationH3IndexFromUnidirectionalEdge(el);
        tempList.push(calculatedH3);
      });
      return tempList;
    },
    getDefaultHexagonList() {
      let tempHexagonList = [];
      if (this.outletData) {
        const outletHexagon = geoToH3(
          this.outletData.latitude,
          this.outletData.longitude,
          8
        );
        tempHexagonList = [...this.getNeighbourHexagons(outletHexagon)];
        if (
          Object.prototype.hasOwnProperty.call(
            this.outletData,
            "hexagon_zone"
          ) &&
          Object.prototype.hasOwnProperty.call(
            this.outletData.hexagon_zone,
            "allowed_list"
          )
        ) {
          this.outletData.hexagon_zone.allowed_list.forEach((el) => {
            tempHexagonList = tempHexagonList.concat(
              this.getNeighbourHexagons(el)
            );
          });
        }
      }
      if (
        this.outletData &&
        Object.prototype.hasOwnProperty.call(this.outletData, "hexagon_zone")
      ) {
        const calculatedH3List = this.generatePolygonsFromHexagonList(
          this.outletData.hexagon_zone.blocked_list
        ).concat(this.generatePolygonsFromHexagonList(tempHexagonList));
        return calculatedH3List;
      }
    },
    getOutletData(outletId) {
      this.loading = true;
      const includeFields = [
        "_id",
        "full_name_mon",
        "full_name_eng",
        "hexagon_zone",
        "is_delivery_by_outlet",
        "location",
        "client_category",
        "delivery_zone_distance",
      ];
      mAxios
        .post(`/outlet/${outletId}/get`, { include_fields: includeFields })
        .then((res) => {
          if (res.data.status === "success") {
            this.outletData = res.data.data;
            if (
              Object.prototype.hasOwnProperty.call(this.outletData, "location")
            ) {
              this.position = {
                lat: parseFloat(this.outletData.location.split(",")[0]),
                lng: parseFloat(this.outletData.location.split(",")[1]),
              };
            } else if (
              Object.prototype.hasOwnProperty.call(
                this.outletData,
                "latitude"
              ) &&
              Object.prototype.hasOwnProperty.call(this.outletData, "longitude")
            ) {
              this.position = {
                lat: this.outletData.latitude,
                lng: this.outletData.longitude,
              };
            }
            this.center = this.position;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((e) => {
          this.$message({
            message: e.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    getRandomColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
    getPolygonFillColor(type, h3) {
      if (
        type === "allowed_list" &&
        this.outletData.hexagon_zone.blocked_list.includes(h3)
      ) {
        return "#FF0000";
      }
      if (
        Object.prototype.hasOwnProperty.call(this.outletData, "hexagon_zone") &&
        Object.prototype.hasOwnProperty.call(
          this.outletData.hexagon_zone,
          type
        ) &&
        this.outletData.hexagon_zone[type].includes(h3)
      ) {
        return "#000000";
      }
      return "#FFFFFF";
    },
    getPolygonFillOpacity(type, h3) {
      if (
        type === "allowed_list" &&
        this.outletData.hexagon_zone.blocked_list.includes(h3)
      ) {
        return true;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.outletData, "hexagon_zone") &&
        Object.prototype.hasOwnProperty.call(
          this.outletData.hexagon_zone,
          type
        ) &&
        this.outletData.hexagon_zone[type].includes(h3)
      ) {
        return true;
      }
      return false;
    },
    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach((el) => {
        const polygon = h3ToGeoBoundary(el);
        const temp = {
          label: el,
          points: [],
        };
        polygon.forEach((item) => {
          temp.points.push({
            lat: item[0],
            lng: item[1],
          });
        });
        tempData.push(temp);
      });
      return tempData;
    },
  },
};
</script>
<style></style>
