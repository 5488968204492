<template>
  <div v-if="currentOutlet !== null">
    <header class="page-header ml10">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <title-item title="Хүргэлтийн бүс" />
        </el-col>
      </el-row>
    </header>
    <div class="panel" style="padding-bottom: 0; border-radius: 0">
      <el-row :gutter="0" type="flex">
        <el-col :span="8" class="border-right">
          <ul class="list-style-none mt0 mb0 p0 menu-list">
            <li
              v-for="(outlet, index) in $root.chosenOutlets"
              :key="index"
              :class="outlet._id === currentOutlet._id ? 'active' : ''"
              @click="setCurrentOutlet(outlet)"
            >
              <span class="mr10">{{ index + 1 }}.</span>
              {{ outlet.full_name_eng }}
            </li>
          </ul>
          <el-button
            v-if="outletData.is_delivery_by_outlet"
            style="width: 100%; margin-top: 10px"
            @click="registerDeliveryMan"
            type="primary"
          >
            <span class="el-icon-plus mr10"> Хүргэгч бүртгэх </span>
          </el-button>
        </el-col>
        <el-col :span="18">
          <div style="margin: 20px" v-if="isViewState">
            <el-form status-icon class="demo-ruleForm">
              <el-form-item label="Хүргэгчийн РД:">
                <el-input
                  placeholder="Хүргэгчийн РД"
                  type="text"
                  style="width: auto"
                  v-model="registerNumber"
                ></el-input>
              </el-form-item>
              <el-form-item label="Хүргэгчийн утасны дугаар:">
                <el-input
                  placeholder="Хүргэгчийн утасны дугаар"
                  type="number"
                  style="width: auto"
                  v-model="phoneNumber"
                  maxlength="8"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="isViewState = false">Буцах</el-button>
                <el-button @click="registerNewDriver" type="primary"
                  >Бүртгэх</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div v-else>
            <MapComponent :currentOutlet="currentOutlet" />
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="mobile">
      <el-select
        v-model="currentId"
        @change="changeOutlet(currentId)"
        style="width: 98%; padding-left: 1%; padding-right: 1%"
      >
        <el-option
          v-for="(outlet, index) in $root.chosenOutlets"
          :key="index"
          :label="outlet.full_name_mon"
          :value="outlet._id"
        ></el-option>
      </el-select>
      <el-button
        v-if="currentOutlet.is_delivery_by_outlet"
        style="width: 100%; margin-top: 10px"
        @click="registerDeliveryMan"
        type="primary"
      >
        <span class="el-icon-plus mr10"> Хүргэгч бүртгэх </span>
      </el-button>
      <el-divider></el-divider>
      <div style="margin: 10px" v-if="isViewState">
        <el-form status-icon class="demo-ruleForm">
          <el-form-item label="Хүргэгчийн РД:">
            <el-input
              placeholder="Хүргэгчийн РД:"
              type="text"
              v-model="registerNumber"
            ></el-input>
          </el-form-item>
          <el-form-item label="Хүргэгчийн утасны дугаар:">
            <el-input
              placeholder="Хүргэгчийн утасны дугаар:"
              type="number"
              v-model="phoneNumber"
              maxlength="8"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="isViewState = false">Буцах</el-button>
            <el-button @click="registerNewDriver" type="primary"
              >Бүртгэх</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <MapComponent :currentOutlet="currentOutlet" />
      </div>
    </div>
  </div>
</template>

<script>
import MapComponent from "./components/MapComponent.vue";
import TitleItem from "../../../components/items/titleItem.vue";
import mAxios from "../../../helper/axiosInstance";
export default {
  name: "DeliveryZone",
  components: { MapComponent, TitleItem },
  data() {
    return {
      registerNumber: "",
      phoneNumber: "",
      currentOutlet: null,
      outletData: null,
      loading: null,
      currentId: "",
      isViewState: false,
    };
  },
  mounted() {
    if (this.$root.chosenOutlets.length > 0) {
      this.setCurrentOutlet(this.$root.chosenOutlets[0]);
    }
  },
  methods: {
    registerNewDriver() {
      const payload = {
        phone: this.phoneNumber,
        regNo: this.registerNumber,
        outlet_id: this.currentId,
      };

      mAxios
        .post(
          "https://rest.toktok.mn/client-api-test/main/outlet/registerNewDriver",
          payload
        )
        .then((response) => {
          if (response?.data?.message !== "Жолооч олдсонгүй !!!") {
            this.$message({
              message: response?.data?.message,
              type: "success",
            });
          } else {
            this.$message({
              message: response?.data?.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || "Хүргэгч бүртгэхэд алдаа гарлаа";
          this.$message({
            message: errorMessage,
            type: "error",
          });
        });
    },
    registerDeliveryMan() {
      this.isViewState = true;
    },
    setCurrentOutlet(outlet) {
      this.currentOutlet = outlet;
      this.currentId = outlet._id;
      this.loading = true;
      const includeFields = [
        "_id",
        "full_name_mon",
        "full_name_eng",
        "hexagon_zone",
        "is_delivery_by_outlet",
        "location",
        "client_category",
        "delivery_zone_distance",
      ];
      mAxios
        .post(`/outlet/${outlet._id}/get`, { include_fields: includeFields })
        .then((res) => {
          if (res.data.status === "success") {
            this.outletData = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((e) => {
          this.$message({
            message: e.response.data.message,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeOutlet(id) {
      this.$root.chosenOutlets.map((res) => {
        if (res._id === id) {
          this.setCurrentOutlet(res);
        }
      });
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  .panel {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .mobile {
    display: none;
  }
}
</style>
